import React, { useState, useEffect, useRef } from 'react';
import { motion } from 'framer-motion';
import { Star, Zap, Brain, Glasses, ChevronDown, ExternalLink } from 'lucide-react';
import { Helmet } from 'react-helmet';
import visionProImage from './images/applevisionpro.png'; // Update this path to your image

const LandingPage = () => {
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });
  const videoSectionRef = useRef(null);
  const experiencesSectionRef = useRef(null);
  const kickstarterSectionRef = useRef(null);
  
  useEffect(() => {
    const handleMouseMove = (e) => {
      setMousePosition({ x: e.clientX, y: e.clientY });
    };

    window.addEventListener('mousemove', handleMouseMove);

    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
    };
  }, []);

  const scrollToSection = (ref) => {
    ref.current?.scrollIntoView({ behavior: 'smooth' });
  };

  const experiences = [
    {
      icon: <Zap className="w-12 h-12 text-yellow-400" />,
      title: "Mind-Bending Visuals",
      description: "Immerse yourself in breathtaking psychedelic landscapes that push the boundaries of reality."
    },
    {
      icon: <Brain className="w-12 h-12 text-purple-400" />,
      title: "Interactive Alien Encounters",
      description: "Engage with sentient alien beings in meaningful, story-driven interactions that adapt to your choices."
    },
    {
      icon: <Glasses className="w-12 h-12 text-blue-400" />,
      title: "Multi-Sensory Storytelling",
      description: "Experience a narrative that unfolds through sight, sound, and spatial awareness, fully utilizing Vision Pro's capabilities."
    }
  ];

  const AnimatedDownArrow = ({ onClick }) => (
    <motion.div 
      className="cursor-pointer"
      animate={{ y: [0, 10, 0] }}
      transition={{ duration: 1.5, repeat: Infinity, ease: "easeInOut" }}
      onClick={onClick}
    >
      <ChevronDown className="w-16 h-16 text-white" />
    </motion.div>
  );

  return (
    <div className="w-full bg-black overflow-x-hidden relative">
      <Helmet>
        <title>Close Encounters - A Psychedelic Alien Adventure</title>
        <meta name="description" content="Embark on a psychedelic alien adventure with Close Encounters, an immersive interactive experience utilizing Vision Pro's capabilities." />
        <meta property="og:title" content="Close Encounters - A Psychedelic Alien Adventure" />
        <meta property="og:description" content="Embark on a psychedelic alien adventure with Close Encounters, an immersive interactive experience utilizing Vision Pro's capabilities." />
        <meta property="og:image" content="path/to/social-media-image.jpg" />
        <meta property="og:url" content="https://yourlandingpageurl.com" />
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>

      {/* Starfield background */}
      {[...Array(100)].map((_, i) => (
        <motion.div
          key={i}
          className="fixed w-1 h-1 bg-white rounded-full"
          animate={{
            x: [Math.random() * window.innerWidth, Math.random() * window.innerWidth],
            y: [Math.random() * window.innerHeight, Math.random() * window.innerHeight],
            scale: [0, 1, 0],
          }}
          transition={{
            duration: Math.random() * 10 + 10,
            repeat: Infinity,
            ease: "linear",
          }}
        />
      ))}
      
      {/* Psychedelic background */}
      <motion.div
        className="fixed inset-0 bg-gradient-to-r from-purple-500 via-pink-500 to-red-500 opacity-50"
        animate={{
          backgroundPosition: [`0% 50%`, `100% 50%`, `0% 50%`],
        }}
        transition={{ duration: 10, repeat: Infinity, ease: "linear" }}
      />

      {/* Hero Content */}
      <div className="relative z-10 h-screen flex flex-col items-center justify-center text-white text-center px-4">
        <motion.h1 
          className="text-6xl font-bold mb-4"
          initial={{ y: -50, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ duration: 1 }}
        >
          Close Encounters
        </motion.h1>
        <motion.p 
          className="text-xl mb-8"
          initial={{ y: 50, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ duration: 1, delay: 0.5 }}
        >
          Embark on a Psychedelic Alien Adventure in Apple Vision Pro
        </motion.p>
        <motion.img 
          src={visionProImage} 
          alt="Apple Vision Pro" 
          className="w-64 h-auto mb-8"
          initial={{ scale: 0.8, opacity: 0 }}
          animate={{ scale: 1, opacity: 1 }}
          transition={{ duration: 1, delay: 1 }}
        />
        <div className="absolute bottom-12">
          <AnimatedDownArrow onClick={() => scrollToSection(videoSectionRef)} />
        </div>
      </div>

      {/* Video Section */}
      <div ref={videoSectionRef} className="relative z-10 min-h-screen flex flex-col items-center justify-center text-white px-4 py-16">
        <h2 className="text-4xl font-bold mb-12">Experience the Adventure</h2>
        <div className="w-full max-w-4xl aspect-video bg-gray-800 rounded-lg overflow-hidden mb-16">
          {/* Replace with your actual video component or embed code */}
          <div className="w-full h-full flex items-center justify-center text-2xl">
            <iframe 
              width="100%" 
              height="100%" 
              src="https://www.youtube.com/embed/IKuGFJ4HeUU" 
              frameBorder="0" 
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
              allowFullScreen
              title="Close Encounters Video"
            ></iframe>
          </div>
        </div>
        <AnimatedDownArrow onClick={() => scrollToSection(experiencesSectionRef)} />
      </div>

      {/* Experiences Section */}
      <div ref={experiencesSectionRef} className="relative z-10 min-h-screen flex flex-col items-center justify-center text-white px-4 py-16">
        <h2 className="text-4xl font-bold mb-12">Immersive Experiences</h2>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8 max-w-6xl mb-16">
          {experiences.map((exp, index) => (
            <motion.div
              key={index}
              className="bg-gray-800 bg-opacity-50 p-6 rounded-lg"
              initial={{ y: 50, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              transition={{ duration: 0.5, delay: index * 0.2 }}
            >
              <div className="flex justify-center mb-4">{exp.icon}</div>
              <h3 className="text-xl font-semibold mb-2">{exp.title}</h3>
              <p>{exp.description}</p>
            </motion.div>
          ))}
        </div>
        <AnimatedDownArrow onClick={() => scrollToSection(kickstarterSectionRef)} />
      </div>

      {/* Kickstarter Section */}
      <div ref={kickstarterSectionRef} className="relative z-10 min-h-screen flex flex-col items-center justify-center text-white px-4 py-16">
        <motion.h2 
          className="text-4xl font-bold mb-8"
          initial={{ y: 50, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ duration: 0.5 }}
        >
          Support Our Vision
        </motion.h2>
        <motion.p 
          className="text-xl mb-8 text-center max-w-2xl"
          initial={{ y: 50, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ duration: 0.5, delay: 0.2 }}
        >
          We're running a Kickstarter campaign to bring "Close Encounters" to life. Your support will help us create an unprecedented immersive experience that pushes the boundaries of storytelling in virtual reality.
        </motion.p>
        <motion.a
          href="https://www.kickstarter.com/projects/psychalien/psychedelic-alien-a-vision-pro-experience" // Replace with your actual Kickstarter campaign link
          target="_blank"
          rel="noopener noreferrer"
          className="flex items-center justify-center px-8 py-4 bg-green-500 text-white rounded-full text-xl font-semibold hover:bg-green-600 transition-colors"
          initial={{ y: 50, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ duration: 0.5, delay: 0.4 }}
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
        >
          Back Us on Kickstarter
          <ExternalLink className="ml-2 w-6 h-6" />
        </motion.a>
      </div>

      {/* Floating alien */}
      <motion.div
        className="fixed w-32 h-32 pointer-events-none z-50"
        animate={{
          x: mousePosition.x - 64,
          y: mousePosition.y - 64,
        }}
        transition={{ type: "spring", stiffness: 50, damping: 10 }}
      >
        <Star className="w-full h-full text-green-400" />
      </motion.div>
    </div>
  );
};

export default LandingPage;
